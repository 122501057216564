import SearchIcon from "../assets/icons/search-icon.png";

interface IProps {
  className?: string;
}

const NavigationSearch: React.FC<IProps> = ({ className }) => {
  return (
    <>
      <div className={`relative max-w-[450px] mx-auto ${className}`}>
        <input
          type="text"
          placeholder="Search Subject"
          className="relative w-full overflow-hidden rounded-lg px-4 py-2.5 text-[#4B5563] placeholder:!text-primary placeholder:!font-medium border border-[#BBD1D1] focus:border-primary focus-within:border-primary transition-all duration-300 ease-in-out"
        />
        <button type="submit" className="absolute right-[12px] top-[10px]">
          <img src={SearchIcon} alt="Search Icon" />
        </button>
      </div>
    </>
  );
};

export default NavigationSearch;
