import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Button,
  IconButton,
} from "@material-tailwind/react";
import LOGO from "../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import useAuth from "../utils/context/auth-context";
import Avatar from "react-avatar";
import useInfo from "../utils/context/info-context";
import useCart from "../utils/context/cart-context";
import { BsHandbag } from "react-icons/bs";
import NavigationSearch from "./search";

function TopNav() {
  const { storageBucketImageRoot } = useInfo();
  const { auth, ...user } = useAuth();
  const [openNav, setOpenNav] = useState(false);
  const { cart } = useCart();
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="container  pt-4 lg:p-0 flex flex-col gap-2 lg:mb-0 lg:flex-row lg:items-center lg:justify-start lg:gap-4 xl:gap-6">
      {/* <NavLink
        end
        to="/"
        className={(_) =>
          "group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-accent" : " text-primary")
        }
        onClick={() => setOpenNav(false)}
      >
        Home
      </NavLink> */}
      <NavLink
        to="/courses"
        className={(_) =>
          "group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-accent" : " text-primary")
        }
        onClick={() => setOpenNav(false)}
      >
        Courses
      </NavLink>
      <NavLink
        to="/tutors"
        className={(_) =>
          "group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-accent" : " text-primary")
        }
        onClick={() => setOpenNav(false)}
      >
        Tutors
      </NavLink>
      <NavLink
        to="/institutes"
        className={(_) =>
          "group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-accent" : " text-primary")
        }
        onClick={() => setOpenNav(false)}
      >
        Institutes
      </NavLink>
      <a
        href="https://support.ewings.lk/"
        target="_blank"
        rel="noreferrer"
        className={`group hover:opacity-100 animate text-lg animate hover:text-primary text-primary`}
      >
        Support
      </a>
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/support"
        className={(_) => "group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-accent" : " text-primary")}
      >
        Support
      </NavLink> */}
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/about-us"
        className={(_) => "group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-accent" : " text-primary")}
      >
        About Us
      </NavLink> */}
    </ul>
  );

  return (
    <div className="fixed w-full z-50">
      <Navbar
        color="transparent"
        shadow={false}
        blurred={false}
        fullWidth={true}
        className={`bg-light p-0 rounded-none w-full ${
          openNav ? "shadow-lg" : ""
        }`}
      >
        <div className="relative flex items-center pl-4  lg:pr-4 xl:px-16 justify-between overflow-hidden gap-x-4 h-20 max-h-20 ">
          <div className="relative flex-initial w-10 py-4 mr-4">
            <NavLink to="/">
              <img src={LOGO} alt="Aplus" className="w-full " />
            </NavLink>
          </div>
          <div className="relative flex-1 hidden lg:block">{navList}</div>
          <div className="relative flex-1 hidden lg:block">
            <NavigationSearch />
          </div>
          <div className="relative flex-1">
            {auth ? (
              <NavLink to="/profile" onClick={() => setOpenNav(false)}>
                <div className="items-center justify-end flex">
                  {user.profile?.avatar_url ? (
                    <img
                      src={storageBucketImageRoot + user.profile?.avatar_url}
                      alt={user.fname}
                      className="rounded-full w-8 h-8 md:w-10 md:h-10"
                    />
                  ) : (
                    <div className="rounded-full w-8 h-8 md:w-10 md:h-10 flex items-center justify-center overflow-hidden">
                      <Avatar
                        name={user.fname + " " + user.lname}
                        color="#249A97"
                        size="40"
                        round={true}
                      />
                    </div>
                  )}
                  <h4 className="text-primary text-sm md:text-base lg:text-xl capitalize ml-2  truncate">
                    {user.fname}{" "}
                    <span className="font-light">{user.lname}</span>
                  </h4>
                  <Link
                    to="/cart"
                    className="ml-4 w-10 h-10 bg-white flex items-center justify-center rounded-lg relative"
                  >
                    <BsHandbag className="text-primary" size={20} />
                    <p className="bg-primary text-white border border-white rounded-full absolute -top-2 -right-2 flex items-center justify-center w-6 h-6 text-sm">
                      {Object.keys(cart).length}
                    </p>
                  </Link>
                </div>
              </NavLink>
            ) : (
              <div className="flex items-center justify-end ">
                <div className="grid grid-cols-2 gap-2">
                  <NavLink to="/auth/login" onClick={() => setOpenNav(false)}>
                    <Button
                      variant="outlined"
                      size="sm"
                      color="green"
                      ripple={true}
                      className="invisible lg:visible border-primary bg-primary font-medium capitalize text-base border-2 button-sm"
                    >
                      <p className="text-white">Login</p>
                    </Button>
                  </NavLink>
                  <NavLink
                    to="/auth/register"
                    onClick={() => setOpenNav(false)}
                  >
                    <Button
                      variant="filled"
                      size="sm"
                      color="white"
                      ripple={true}
                      className="invisible lg:visible font-normal capitalize text-base"
                    >
                      <p className=" text-primary font-semibold">Sign Up</p>
                    </Button>
                  </NavLink>
                </div>
                <Link
                  to="/cart"
                  className="ml-4 w-10 h-10 bg-white flex items-center justify-center rounded-lg relative"
                >
                  <BsHandbag className="text-primary" size={20} />
                  <p className="bg-primary text-white border border-white rounded-full absolute -top-2 -right-2 flex items-center justify-center w-6 h-6 text-sm">
                    {Object.keys(cart).length}
                  </p>
                </Link>
              </div>
            )}
          </div>
          <div className="flex-initial block lg:hidden">
            <div className="bg-primary px-2 py-6">
              <IconButton
                variant="text"
                className="ml-auto h-8 w-8 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent  text-white px-4"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="h-8 w-8"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </IconButton>
            </div>
          </div>
        </div>
        <MobileNav open={openNav} className="">
          <div className="p-4">
            {auth ? (
              <>
                {/* <NavLink to="/profile" onClick={() => setOpenNav(false)}>
                  <div className="flex items-center justify-start">
                      {user.profile?.avatar_url ? (
                  <img src={storageBucketImageRoot + user.profile?.avatar_url} alt={user.fname} className="mx-auto rounded-full w-10 h-10" />
                ) : (
                  <Avatar name={user.fname + " " + user.lname} color="#227AFF" size="40" round={true} className="mx-auto" />
                )}
                    <h4 className="text-black capitalize">
                      {user.fname} <span className="font-light">{user.lname}</span>
                    </h4>
                  </div>
                </NavLink> */}
              </>
            ) : (
              <div className="grid grid-cols-2 gap-2 mx-auto max-w-xs">
                <NavLink to="/auth/login" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="outlined"
                    size="sm"
                    color="white"
                    ripple={true}
                    className="font-medium capitalize text-base border-2 button-sm border-light"
                  >
                    <p className="px-2 text-primary ">Login</p>
                  </Button>
                </NavLink>
                <NavLink to="/auth/register" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="filled"
                    size="sm"
                    color="white"
                    ripple={true}
                    className="font-normal capitalize text-base bg-primary"
                  >
                    <p className="px-2 text-white">Sign Up</p>
                  </Button>
                </NavLink>
              </div>
            )}
            <NavigationSearch className="min-w-full" />
            {navList}
          </div>
        </MobileNav>
      </Navbar>
    </div>
  );
}

export default TopNav;
